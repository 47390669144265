export default {
  errorAlert: function(e){
    // e = Ajaxresponseの要素(JSONparse化済み)
    let errorMessage = this.setupToStringMessages(e.response.data.message)
    $('.js-messages').html(errorMessage);
  },
  successAlert: function(response_message){
    var fixed_message = '処理に成功しました'
    if (response_message) {
      fixed_message = response_message
    }
    $('.js-messages').html(
      '<div class="alert alert-dismissible alert-success" role="alert">' +
      '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      fixed_message +
      '</div>'
    );
  },
  errorModalAlert: function(e){
    // e = Ajaxresponseの要素(JSONparse化済み)
    let errorMessage = this.setupToStringMessages(e.response.data.message)
    $('.js-modal-messages').html(errorMessage);
  },
  successModalAlert: function(response_message){
    var fixed_message = '処理に成功しました'
    if (response_message) {
      fixed_message = response_message
    }
    $('.js-modal-messages').html(
      '<div class="alert alert-dismissible alert-success" role="alert">' +
      '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      fixed_message +
      '</div>'
    );
  },
  clearModalAlert: function () { 
    $('.js-modal-messages').html('')
  },

  // responseType:ArrayBuffer & utf-8
  errorAlertWithArrayBuffer: function(e){
    let dataView = new DataView(e.response.data);
    let decoder = new TextDecoder('utf8');
    let obj = JSON.parse(decoder.decode(dataView));
    let errorMessage = this.setupToStringMessages(obj['message'])
    $('.js-messages').html(errorMessage);
  },

  // HTMLを組み立てるメソッド
  errorMessageHtml: function(message) {
    return ('<div class="alert alert-dismissible alert-danger" role="alert">' +
      '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      message +
      '</div>')
  },
  // responseMessageがStringArray別に処理してHTML文字列で返す
  setupToStringMessages: function(responseMessage) {
    var message = '';
    if(Array.isArray(responseMessage)){
      let self = this
      responseMessage.forEach(function(msg) {
        message += self.errorMessageHtml(msg);
      })
    } else {
      message = this.errorMessageHtml(responseMessage);
    }
    return message
  }
};
